import { Component, OnInit } from '@angular/core';
import SwiperCore, {Pagination, Autoplay} from 'swiper/core';

SwiperCore.use([Pagination, Autoplay]);

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  constructor() { }

  isDown = false;
  startX;
  scrollLeft;

  ngOnInit(): void {
  }

}
