import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import SwiperCore, { Pagination, Autoplay } from 'swiper/core';
import {
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { RECOMMENDS, Store, STORE } from 'src/app/data/marriott.model';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Pagination, Autoplay]);

// interface Filter {
//   value: string;
//   viewValue: string;
// }
// interface Sort {
//   value: string;
//   viewValue: string;
// }
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('title', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(50%)' }),
        animate(
          '0.5s ease-in-out',
          style({ opacity: 1, transform: 'translateX(0%)' })
        ),
      ]),
    ]),
  ],
})
export class HomeComponent implements OnInit {
  stores: Store[] = STORE;
  recommends: Store[] = RECOMMENDS;

  constructor(private router: Router) {}

  isDown = false;
  startX;
  scrollLeft;

  address: string = '';
  finalAddress: string = '';

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  slideNext() {
    this.swiper.swiperRef.slideNext(100);
  }
  slidePrev() {
    this.swiper.swiperRef.slidePrev(100);
  }

  @ViewChild('swiperMobile', { static: false }) swiperMobile?: SwiperComponent;
  slideNextMobile() {
    this.swiperMobile.swiperRef.slideNext(100);
  }
  slidePrevMobile() {
    this.swiperMobile.swiperRef.slidePrev(100);
  }

  ngOnInit(): void {
    // let slider = document.querySelector('.store-slider');
    // slider.addEventListener('mousedown', (e) => {
    //   this.isDown = true;
    //   slider.classList.add('active');
    //   this.startX = e.pageX - slider.offsetLeft;
    //   this.scrollLeft = slider.scrollLeft;
    // });
    // slider.addEventListener('mouseleave', () => {
    //   this.isDown = false;
    //   slider.classList.remove('active');
    // });
    // slider.addEventListener('mouseup', () => {
    //   this.isDown = false;
    //   slider.classList.remove('active');
    // });
    // slider.addEventListener('mousemove', (e) => {
    //   if(!this.isDown) return;
    //   e.preventDefault();
    //   const x = e.pageX - slider.offsetLeft;
    //   const walk = (x - this.startX) * 3; //scroll-fast
    //   slider.scrollLeft = this.scrollLeft - walk;
    //   console.log(walk);
    // });
  }

  goStore(): void {
    this.router.navigate(['/marriott']);
  }

  filters = new FormControl();
  filterList: string[] = ['Hotel', 'Outlet', 'Cuisine'];

  sorts = new FormControl();
  sortList: string[] = ['Recently Added', 'Most Popular', 'Rating'];

  addressClicked(): void {
    // window.location = <any> '#find-restaurant';
    this.finalAddress = this.address;
    let findRestaurant = document.getElementById('find-restaurant');
    findRestaurant.scrollIntoView(true);
  }
}
