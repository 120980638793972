export interface Store {
  img: string;
  name: string;
  hotel: string;
  address: string;
  phone: string;
  icon: string;
}

export const STORE: Store[] = [
  {
    img: 'url(assets/images/marriott-latest-reciepe.store.jpeg)',
    name: 'Latest Recipe',
    hotel: 'Le Meridien Bangkok',
    address:
      '40/5 Thanon Surawong, Si Phraya, Bang Rak, Bangkok 10500, Thailand',
    phone: '02-3444210',
    icon: 'url(assets/images/marriott-meridien-icon.png)',
  },
  {
    img: 'url(assets/images/marriott-yao-store.jpg)',
    name: 'Yao Restaurant',
    hotel: 'Bangkok Marriott Hotel The Surawongse',
    address:
      '32nd Fl. - Bangkok Marriott Hotel The Surawongse, 262 Thanon Surawong, Si Phraya, Bang Rak, Bangkok 10500, Thailand',
    phone: '02-0885666',
    icon: 'url(assets/images/marriott-icon.png)',
  },
  {
    img: 'url(assets/images/marriott-thara-thong-store.jpeg)',
    name: 'Thara Thong',
    hotel: 'Royal Orchid Sheraton Hotel & Towers',
    address: '2 Charoen Krung Rd, Siphya, Bang Rak, Bangkok 10500, Thailand',
    phone: '02-3444210',
    icon: 'url(assets/images/marriott-sheraton-icon.png)',
  },
  {
    img: 'url(assets/images/marriott-the-silk-road-store.jpeg)',
    name: 'The Silk Road',
    hotel: 'The Athenee Hotel',
    address: '61 Wireless Rd, Lumphini, Pathum Wan, Bangkok 10330, Thailand',
    phone: '02-6508800',
    icon: 'url(assets/images/marriott-luxury-icon.png)',
  },
  {
    img: 'url(assets/images/marriott-the-kitchen-table-store.jpg)',
    name: 'The Kitchen Table',
    hotel: 'W Bangkok',
    address: '108 N Sathon Rd, Silom, Bang Rak, Bangkok 10500, Thailand',
    phone: '02-3444210',
    icon: 'url(assets/images/marriott-whotel-icon.png)',
  },
  {
    img: 'url(assets/images/marriott-viu-store.jpeg)',
    name: 'Viu',
    hotel: 'St Regis Bangkok',
    address:
      '159 Rajadamri Road Lumphini, Sub District Bangkok 10330, Thailand',
    phone: '02-2077777',
    icon: 'url(assets/images/marriott-regis-icon.png)',
  },
];

export const RECOMMENDS: Store[] = [
  {
    img: 'url(assets/images/marriott-thara-thong-recommend.jpg)',
    name: 'Thara Thong',
    hotel: 'Royal Orchid Sheraton Hotel & Towers',
    address: '2 Charoen Krung Rd, Siphya, Bang Rak, Bangkok 10500, Thailand',
    phone: '02-3444210',
    icon: 'url(assets/images/marriott-sheraton-icon.png)',
  },
  {
    img: 'url(assets/images/marriott-yao-recommend.jpeg)',
    name: 'Yao Restaurant',
    hotel: 'Bangkok Marriott Hotel The Surawongse',
    address:
      '32nd Fl. - Bangkok Marriott Hotel The Surawongse, 262 Thanon Surawong, Si Phraya, Bang Rak, Bangkok 10500, Thailand',
    phone: '02-0885666',
    icon: 'url(assets/images/marriott-icon.png)',
  },
  {
    img: 'url(assets/images/marriott-latest-reciepe-recommend.jpg)',
    name: 'Latest Recipe',
    hotel: 'Le Meridien Bangkok',
    address:
      '40/5 Thanon Surawong, Si Phraya, Bang Rak, Bangkok 10500, Thailand',
    phone: '02-3444210',
    icon: 'url(assets/images/marriott-meridien-icon.png)',
  },
  {
    img: 'url(assets/images/marriott-viu-recommend.jpg)',
    name: 'Viu',
    hotel: 'St Regis Bangkok',
    address:
      '159 Rajadamri Road Lumphini, Sub District Bangkok 10330, Thailand',
    phone: '02-2077777',
    icon: 'url(assets/images/marriott-regis-icon.png)',
  },
  {
    img: 'url(assets/images/marriott-the-silk-road-recommend.jpg)',
    name: 'The Silk Road',
    hotel: 'The Athenee Hotel',
    address: '61 Wireless Rd, Lumphini, Pathum Wan, Bangkok 10330, Thailand',
    phone: '02-6508800',
    icon: 'url(assets/images/marriott-luxury-icon.png)',
  },
  {
    img: 'url(assets/images/marriott-the-kitchen-table-recommend.jpg)',
    name: 'The Kitchen Table',
    hotel: 'W Bangkok',
    address: '108 N Sathon Rd, Silom, Bang Rak, Bangkok 10500, Thailand',
    phone: '02-3444210',
    icon: 'url(assets/images/marriott-whotel-icon.png)',
  },
];
