import { Component } from '@angular/core';
import { ContextService } from './services/context.service';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('title', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(50%)' }),
        animate(
          '1s ease-in-out',
          style({ opacity: 1, transform: 'translateX(0%)' })
        )
      ])
    ])
  ]
})
export class AppComponent {
  title = 'accor-hotel';

  constructor(public context: ContextService){

  }

  acceptClicked(){
    this.context.acceptedCookie = true;
  }
}
