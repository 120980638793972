import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marriott',
  templateUrl: './marriott.component.html',
  styleUrls: ['./marriott.component.scss']
})
export class MarriottComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
