<div class="content">
  <div class="main-wrapper">
    <div class="review-heading">
      <div class="heading-div">
        <p>Client Testimonials</p>
        <button mat-flat-button class="review-btn">+ Leave a review</button>
      </div>
    </div>

    <div class="review-content">
      <div class="list-review">
        <div class="review-div">
          <div class="review-box">
            <div class="review-img">
              <div class="img-box" style="background-image: url(assets/images/reviews-1.png);"></div>
            </div>
            <div class="review-text">
              <p>
                Their Sunday brunch is excellent! Extensive choice of food,
                starting from cheeses & cold cuts all the way to desserts.
                Lovely ambience as well. Special mention to Miss Banky and her
                team who were the perfect hosts and made us feel so at ease. We
                will definitely be back!!
              </p>
              <span>Glain Luigi / Red Oven</span>
            </div>
          </div>
        </div>

        <div class="review-div">
          <div class="review-box">
            <div class="review-img">
              <div class="img-box" style="background-image: url(assets/images/reviews-2.png);"></div>
            </div>
            <div class="review-text">
              <p>
                Their Sunday brunch is excellent! Extensive choice of food,
                starting from cheeses & cold cuts all the way to desserts.
                Lovely ambience as well. Special mention to Miss Banky and her
                team who were the perfect hosts and made us feel so at ease. We
                will definitely be back!!
              </p>
              <span>Glain Luigi / Red Oven</span>
            </div>
          </div>
        </div>

        <div class="review-div">
          <div class="review-box">
            <div class="review-img">
              <div class="img-box" style="background-image: url(assets/images/reviews-3.png);"></div>
            </div>
            <div class="review-text">
              <p>
                Their Sunday brunch is excellent! Extensive choice of food,
                starting from cheeses & cold cuts all the way to desserts.
                Lovely ambience as well. Special mention to Miss Banky and her
                team who were the perfect hosts and made us feel so at ease. We
                will definitely be back!!
              </p>
              <span>Glain Luigi / Red Oven</span>
            </div>
          </div>
        </div>

        <div class="review-div">
          <div class="review-box">
            <div class="review-img">
              <div class="img-box" style="background-image: url(assets/images/reviews-4.png);"></div>
            </div>
            <div class="review-text">
              <p>
                Their Sunday brunch is excellent! Extensive choice of food,
                starting from cheeses & cold cuts all the way to desserts.
                Lovely ambience as well. Special mention to Miss Banky and her
                team who were the perfect hosts and made us feel so at ease. We
                will definitely be back!!
              </p>
              <span>Glain Luigi / Red Oven</span>
            </div>
          </div>
        </div>

        <div class="review-div">
          <div class="review-box">
            <div class="review-img">
              <div class="img-box" style="background-image: url(assets/images/reviews-5.png);"></div>
            </div>
            <div class="review-text">
              <p>
                Their Sunday brunch is excellent! Extensive choice of food,
                starting from cheeses & cold cuts all the way to desserts.
                Lovely ambience as well. Special mention to Miss Banky and her
                team who were the perfect hosts and made us feel so at ease. We
                will definitely be back!!
              </p>
              <span>Glain Luigi / Red Oven</span>
            </div>
          </div>
        </div>

        <div class="review-div">
          <div class="review-box">
            <div class="review-img">
              <div class="img-box" style="background-image: url(assets/images/reviews-6.png);"></div>
            </div>
            <div class="review-text">
              <p>
                Their Sunday brunch is excellent! Extensive choice of food,
                starting from cheeses & cold cuts all the way to desserts.
                Lovely ambience as well. Special mention to Miss Banky and her
                team who were the perfect hosts and made us feel so at ease. We
                will definitely be back!!
              </p>
              <span>Glain Luigi / Red Oven</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
