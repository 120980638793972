import { Component, OnInit } from '@angular/core';
import { ContextService } from 'src/app/services/context.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(public context: ContextService, public router: Router) { }

  isLoading = false;

  loginForm: FormGroup;

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
  }

  login(): void {
    if(this.loginForm.invalid) {
      return;
    }
    this.isLoading = true;
    setTimeout(() => {
      this.context.logginedIn = true;
      this.isLoading = false;
      this.router.navigate(['/']);
    }, 1100);
  }

}
