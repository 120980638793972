<div class="content">
  <div class="top-container desktop">
    <div class="input-container">
      <div class="input-box">
        <div class="input-col">
          <h1>Marriott Bonvoy Delivery</h1>
          <p>Enter your address to find restaurants nearby</p>
          <!-- <mat-form-field class="example-full-width">
            <input matInput />
          </mat-form-field> -->
          <div class="input-location-wrapper">
            <input [(ngModel)]="address" type="text" class="input-form" />
            <button mat-icon-button (click)="addressClicked()">
              <span class="material-icons md-18">near_me</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <swiper
      direction="horizontal"
      [navigation]="false"
      [loop]="true"
      [centeredSlides]="true"
      [autoHeight]="false"
      [pagination]="true"
      [autoplay]="{ delay: 5000 }"
      class="slide-show"
    >
      <ng-template swiperSlide>
        <div class="slide-img">
          <img src="assets/images/home-banner1.jpg" />
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="slide-img">
          <img src="assets/images/home-banner2.jpg" />
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="slide-img">
          <img src="assets/images/home-banner3.jpg" />
        </div>
      </ng-template>
    </swiper>
  </div>
  <div class="top-container mobile">
    <div class="input-container">
      <div class="input-box">
        <div class="input-col">
          <h1>Marriot Bonvoy Delivery</h1>
          <p>Enter your address to find restaurants nearby</p>
          <!-- <mat-form-field class="example-full-width">
            <input matInput />
          </mat-form-field> -->
          <div class="input-location-wrapper">
            <input [(ngModel)]="address" type="text" class="input-form" />
            <button mat-icon-button (click)="addressClicked()">
              <span class="material-icons md-18">near_me</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <swiper
      direction="horizontal"
      [navigation]="false"
      [loop]="true"
      [centeredSlides]="true"
      [autoHeight]="false"
      [pagination]="true"
      [autoplay]="{ delay: 5000 }"
      class="slide-show"
    >
      <ng-template swiperSlide>
        <div class="slide-img">
          <img src="assets/images/home-mobile-banner1.jpg" />
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="slide-img">
          <img src="assets/images/home-mobile-banner2.jpg" />
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="slide-img">
          <img src="assets/images/home-mobile-banner3.jpg" />
        </div>
      </ng-template>
    </swiper>
  </div>
  <div class="sticky-bar">
    <div class="joinus-bar">
      <span class="material-icons md-18">local_offer</span>
      <p>Marriot Bonvoy members receive an instant 10% discount at checkout</p>
      <button mat-button>Join Us</button>
    </div>
    <div class="function-bar" id="find-restaurant">
      <div class="function-box">
        <div class="address-box">
          <span>Your Address</span>
          <button mat-button class="address-input">
            <!-- <p>Central World, Rama I Road, Pathum Wan, Pathum Wan District, Bangkok, Thailand</p> -->
            <p>{{ finalAddress }}</p>
            <span class="material-icons md-18">expand_more</span>
          </button>
        </div>
        <div class="search-box">
          <span>Search</span>
          <mat-form-field class="example-full-width">
            <input matInput />
          </mat-form-field>
          <button mat-button>
            <span class="material-icons md-18">search</span>
          </button>
        </div>
        <div class="filter-box">
          <mat-form-field class="select-box filter">
            <mat-label>Filter</mat-label>
            <mat-select multiple>
              <mat-option *ngFor="let filter of filterList" [value]="filter">{{
                filter
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <mat-form-field class="select-box sort">
            <mat-label>Sort by</mat-label>
            <mat-select multiple>
              <mat-option *ngFor="let sort of sortList" [value]="sort">{{
                sort
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-button class="layout">
            <span class="material-icons md-18">grid_view</span>
          </button>
          <button mat-button class="layout">
            <span class="material-icons md-18">view_list</span>
          </button> -->
        </div>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <div class="recommend-box">
      <p>Recommended</p>

      <div class="store-slider desktop">
        <div
          (click)="slidePrev()"
          matRipple
          class="btn-store-back btn-store-arrow"
        >
          <span class="material-icons md-18">chevron_left</span>
        </div>
        <swiper
          #swiper
          direction="horizontal"
          [navigation]="false"
          [centeredSlides]="true"
          [autoHeight]="false"
          [pagination]="false"
          [slidesPerView]="5.2"
          [centeredSlides]="false"
          [spaceBetween]="15"
        >
          <ng-template *ngFor="let recommend of recommends" swiperSlide>
            <div class="slider-store">
              <div
                class="store-button"
                (click)="goStore()"
                [ngStyle]="{ 'background-image': recommend.img }"
              >
                <div class="slider-store-info">
                  <h3>{{ recommend.name }}</h3>
                  <h5>{{ recommend.hotel }}</h5>
                  <p>{{ recommend.address }}</p>
                  <p>{{ recommend.phone }}</p>
                </div>
              </div>
              <div class="brand-icon-box">
                <div
                  class="brand-icon"
                  [ngStyle]="{ background: recommend.icon }"
                ></div>
              </div>
            </div>
          </ng-template>
        </swiper>
        <div
          (click)="slideNext()"
          matRipple
          class="btn-store-forward btn-store-arrow"
        >
          <span class="material-icons md-18">navigate_next</span>
        </div>
      </div>

      <div class="store-slider mobile" id="find-restaurant">
        <div
          (click)="slidePrevMobile()"
          matRipple
          class="btn-store-back btn-store-arrow"
        >
          <span class="material-icons md-18">chevron_left</span>
        </div>
        <swiper
          #swiperMobile
          direction="horizontal"
          [navigation]="false"
          [centeredSlides]="true"
          [autoHeight]="false"
          [pagination]="false"
          [slidesPerView]="1"
          [centeredSlides]="false"
          [spaceBetween]="15"
        >
          <ng-template *ngFor="let recommend of recommends" swiperSlide>
            <div class="slider-store">
              <div
                class="store-button"
                (click)="goStore()"
                [ngStyle]="{ 'background-image': recommend.img }"
              >
                <div class="slider-store-info">
                  <h3>{{ recommend.name }}</h3>
                  <h5>{{ recommend.hotel }}</h5>
                  <p>{{ recommend.address }}</p>
                  <p>02 126 9999</p>
                </div>
              </div>
              <div class="brand-icon-box">
                <div
                  class="brand-icon"
                  style="
                    background: url(assets/images/hotel_08.png);
                    background-size: cover;
                  "
                ></div>
              </div>
            </div>
          </ng-template>
        </swiper>
        <div
          (click)="slideNextMobile()"
          matRipple
          class="btn-store-forward btn-store-arrow"
        >
          <span class="material-icons md-18">navigate_next</span>
        </div>
      </div>
    </div>

    <div class="store-container">
      <div class="store-list">
        <div class="list-heading">
          <p>Find Restaurant near you</p>
          <span>Delivered fresh and hot at your doorstep</span>
        </div>
        <div class="store-wrapper">
          <div
            *ngFor="let store of stores"
            class="store-box"
            (click)="goStore()"
            matRipple
          >
            <div class="store-padding">
              <div class="brand-icon-box">
                <div
                  class="brand-icon"
                  [ngStyle]="{ background: store.icon }"
                ></div>
              </div>
              <div class="store-img">
                <div
                  class="img-box"
                  [ngStyle]="{ 'background-image': store.img }"
                ></div>
              </div>
              <div class="store-details">
                <p class="store-name">{{ store.name }}</p>
                <h4 class="hotel-name">{{ store.hotel }}</h4>
                <div class="address-col col">
                  <span class="material-icons">place</span>
                  <p>{{ store.address }}</p>
                </div>
                <div class="phone-col col">
                  <span class="material-icons">phone</span>
                  <p>{{ store.phone }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--google map-->
    <div class="store-container">
      <div class="store-map">
        <div class="list-heading">
          <p>Browse by location</p>
          <span>Choose from your favourite location</span>
        </div>
        <div class="map-wrapper">
          <div class="map-panel">
            <div class="map-panel-item">
              <span>City</span>
              <span class="material-icons md-18">arrow_drop_down</span>
            </div>
            <div class="map-panel-item">
              <span>Hotel</span>
              <span class="material-icons md-18">arrow_drop_down</span>
            </div>
            <div class="map-panel-item">
              <span>Restaurant</span>
              <span class="material-icons md-18">arrow_drop_down</span>
            </div>
          </div>
          <div class="google-map">
            <img src="assets/images/marriott-google-map.png" />
          </div>
        </div>
      </div>
    </div>
    <!--/google-map-->
  </div>
</div>
<!-- <div class="btn-alarm" matRipple>
  <span class="material-icons">notifications_active</span>
</div>

<div class="btn-messager" matRipple>
  <span class="material-icons">chat</span>
</div> -->
