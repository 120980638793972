import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor(public breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    this.currentDate = new Date();
  }

  showCalendar: boolean = false;
  selected: Date | null;

  selectedMode: string = 'delivery';
  selectedTimeMode: string = 'now';

  currentDate: Date;

  displayDate: string;

  laterClicked(): void {
    this.showCalendar = !this.showCalendar;
  }

  modeSelected(mode: string): void {
    this.selectedMode = mode;
  }

  nowSelected(): void {
    this.selectedTimeMode = 'now';
    this.displayDate = '';
  }

  dateSelected(date): void {
    this.selected = date;
    this.showCalendar = false;
    this.selectedTimeMode = 'later';

    this.displayDate = moment(this.selected).format('MM/DD');
  }

  orderClicked(): void {
    const mode = this.selectedMode === 'delivery' ? 'delivery' : 'takeaway';

    if(this.breakpointObserver.isMatched(Breakpoints.Handset)) {

      const url = `https://accor-hotel-demo-byod.web.app/store/5747986921422848/mode/${mode}`

      window.open(url, '_blank').focus();

      return;
    }
    const url = `https://accor-hotel-demo-web.web.app/web/brand/5083323200176128?mode=${mode}`;

    window.open(url, '_blank').focus();
  }
}
