<div class="content">
  <div class="login-container">
    <p>Login</p>
    <div class="login-box">
      <div class="input-box">
        <form
          class="login-form"
          id="loginForm"
          [formGroup]="loginForm"
          (submit)="login()"
        >
          <mat-form-field class="email-iput">
            <mat-label>Username or email address*</mat-label>
            <input matInput formControlName="username" />
          </mat-form-field>
          <mat-form-field class="password-iput">
            <mat-label>Password*</mat-label>
            <input matInput type="password" formControlName="password" />
          </mat-form-field>
        </form>

        <button mat-button class="lost-pw">Forgot your password?</button>
      </div>
      <div class="reg-col">
        <p>
          Not yet a Marriot Bonvoy Delivery? Sign up
          <a class="sign-up" routerLink="/register"> here </a>
        </p>
      </div>
      <mat-checkbox class="check-box">
        <span>Remember me</span>
      </mat-checkbox>
    </div>

    <button
      mat-flat-button
      class="login-button"
      [disabled]="isLoading"
      type="submit"
      form="loginForm"
    >
      {{ !isLoading ? "Login" : "Loading..." }}
    </button>
  </div>
</div>

<!-- <div class="btn-alarm" matRipple>
    <span class="material-icons">notifications_active</span>
</div>


<div class="btn-messager" matRipple>
    <span class="material-icons">chat</span>
</div> -->
