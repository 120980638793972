<div class="content">
  <div class="info-heading">
    <div class="info-div">
      <div class="left-col">
        <p class="info-title">About Us</p>
        <span
          >The signature restaurant is a favorite haunt among jet-setters with
          adventurous palate who adore quality. By day, Bangkok’s finest
          creations are served here with an a la carte menu alongside an
          international buffet offering limitless variety. By night, the
          restaurant turns its focus to an a la carte menu of carefully selected
          meats prepared using various American cooking techniques, including
          low-temp, slow-cook, smoked, grilled and more.</span
        >
        <button mat-flat-button>
          More about us
          <span class="material-icons">arrow_forward</span>
        </button>
      </div>
      <div class="right-col">
        <swiper
          direction="horizontal"
          [navigation]="false"
          [loop]="true"
          [centeredSlides]="true"
          [autoHeight]="false"
          [pagination]="true"
          class="slides-wrapper"
        >
          <ng-template swiperSlide>
            <div class="slide-container">
              <div class="slide-box box-1">
                <div
                  class="slide-img"
                  style="background: url(assets/images/slide-img-2.jpg)"
                ></div>
              </div>
              <div class="slide-box box-2">
                <div
                  class="slide-img"
                  style="background: url(assets/images/slide-img-1.jpg)"
                ></div>
              </div>
              <div class="slide-box box-3">
                <div
                  class="slide-img"
                  style="background: url(assets/images/slide-img-3.jpg)"
                ></div>
              </div>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="slide-box box-4">
              <div
                class="slide-img"
                style="background: url(assets/images/slide-img-4.jpg)"
              ></div>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="slide-container-3">
              <div class="slide-box box-5">
                <div
                  class="slide-img"
                  style="background: url(assets/images/slide-img-5.jpg)"
                ></div>
              </div>
              <div class="slide-box box-6">
                <div
                  class="slide-img"
                  style="background: url(assets/images/slide-img-6.jpg)"
                ></div>
              </div>
              <div class="slide-box box-7">
                <div
                  class="slide-img"
                  style="background: url(assets/images/slide-img-7.jpg)"
                ></div>
              </div>
            </div>
          </ng-template>
        </swiper>
      </div>
    </div>
  </div>
</div>
