<div class="container">
  <mat-toolbar class="header">
    <span class="example-spacer"></span>
    <button
      mat-button
      class="example-icon"
      [matMenuTriggerFor]="menu"
      aria-label="Example icon-button with menu icon"
    >
      <p>English</p>
      <span class="material-icons"> expand_more </span>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item>English</button>
      <button mat-menu-item>ภาษาไทย</button>
    </mat-menu>
    <span class="example-spacer"></span>
    <a class="marriott-logo" routerLink="/" @title>
      <img src="assets/images/marriott-logo.jpg" style="height: 70px; width: auto" />
    </a>
    <span class="example-spacer"></span>
    <button
      mat-button
      class="example-icon"
      aria-label="Example icon-button with menu icon"
      routerLink="/login"
      *ngIf="!context.logginedIn"
    >
      Login
    </button>
    <div class="greetings" *ngIf="context.logginedIn">
      Welcome, Jean-Francois
    </div>

    <span class="example-spacer"></span>
  </mat-toolbar>

  <router-outlet></router-outlet>

  <div class="footer">
    <div class="spacing-box"></div>
    <div class="footer-box">
      <div class="footer-wrapper">
        <!-- <div class="contact-box">
          <div class="left-col">
            <div class="icon-box">
              <img src="assets/images/tabler_coffee.svg" />
            </div>
            <div class="text-area">
              <p>Start working with us</p>
              <span>Let's have coffee and talk business.</span>
            </div>
            <button mat-flat-button>Send Email</button>
          </div>
          <span class="divider"></span>
          <div class="right-col">
            <div class="icon-box">
              <img src="assets/images/cib_line.svg" />
            </div>
            <div class="text-area">
              <p>Want to chat first?</p>
              <span>Chat with us via LINE.</span>
            </div>
            <button mat-flat-button>+1 234 567 89</button>
          </div>
        </div> -->
      </div>
      <div class="content-info">
        <div class="menu-col">
          <p>© 2022．Marriot Bonvoy Delivery</p>
          <div class="menu-box">
            <button mat-button>Home</button>
            <span></span>
            <button mat-button>Services</button>
            <span></span>
            <button mat-button>About</button>
            <span></span>
            <button mat-button>Contact</button>
          </div>
        </div>
        <div class="copy-right">
          <span>Copyright 2022 © Marriot Bonvoy Delivery</span>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="cookies-container" *ngIf="!context.acceptedCookie">
    <div class="cookies-backdrop"></div>
    <div class="cookies-box">
      <button mat-button class="close-btn" (click)="acceptClicked()">
        <span class="material-icons">close</span>
      </button>
      <div class="cookies-div">
        <div mat-dialog-content class="cookies-content">
          <p>
            This website uses cookies in order to offer you the most relevant
            information. Please accept cookies for optimal performance.
          </p>
        </div>
        <div mat-dialog-actions class="cookies-btn">
          <button mat-button class="accept-btn" (click)="acceptClicked()">Yes, I accept Cookies</button>
          <button mat-button class="change-btn">Change Settings</button>
        </div>
      </div>
    </div>
  </div> -->
</div>
