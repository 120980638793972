<div class="content">
  <div class="login-container">
    <img src="assets/images/marriott-register.png" />
  </div>
</div>

<div class="btn-alarm" matRipple>
  <span class="material-icons">notifications_active</span>
</div>

<div class="btn-messager" matRipple>
  <span class="material-icons">chat</span>
</div>
