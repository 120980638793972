import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContextService {

  acceptedCookie: boolean = false;
  logginedIn: boolean = false;

  constructor() { }
}
