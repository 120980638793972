<div class="content">
  <div class="mode-select-bar">
    <div class="btn-bar">
      <div class="mode-btn">
        <button
          mat-button
          [ngClass]="{ focused: selectedMode === 'delivery' }"
          (click)="modeSelected('delivery')"
        >
          Delivery
        </button>
        <button
          mat-button
          [ngClass]="{ focused: selectedMode === 'pickup' }"
          (click)="modeSelected('pickup')"
        >
          Pick Up
        </button>
      </div>
      <div class="sche-btn">
        <button
          mat-button
          [ngClass]="{ focused: selectedTimeMode === 'now' }"
          (click)="nowSelected()"
        >
          Now
        </button>
        <button
          mat-button
          [ngClass]="{ focused: selectedTimeMode === 'later' }"
          (click)="laterClicked()"
        >
          {{ selectedTimeMode === "later" ? displayDate : "Later" }}
          <span class="material-icons">arrow_drop_down</span>
        </button>
        <div class="datepicker" *ngIf="showCalendar">
          <mat-calendar
            (selectedChange)="dateSelected($event)"
            [minDate]="currentDate"
          ></mat-calendar>
        </div>
      </div>
    </div>
  </div>

  <div class="main-wrapper">
    <div class="menu-heading">
      <div class="heading-div">
        <div class="left-col">
          <p>Menu Highlights</p>
          <span
            >With the ambience of a traditional food market, Red Oven adds its
            own contemporary twist to create the unique buffet younger.</span
          >
        </div>
        <div class="right-col">
          <button mat-flat-button class="menu-btn" (click)="orderClicked()">
            Browse full menu & order
            <span class="material-icons">arrow_forward</span>
          </button>
        </div>
      </div>
    </div>

    <div class="menu-content">
      <div class="list-menu">
        <div class="item-div" matRipple>
          <div class="item-box">
            <div class="item-info">
              <p class="item-name">Tom Kha Gai</p>
              <span class="line"></span>
              <div class="tag-row">
                <div class="tag-box" style="background-color: #a69f8e">
                  Gluten Free
                </div>
                <div class="tag-box" style="background-color: #46a482">
                  Vegetarian certified
                </div>
              </div>
              <p class="item-desc">
                Tomatos, basils, balsamico reduction, olive oil
              </p>
              <div class="price-row">
                <span>Member Price</span>
                <p class="discounted-price">฿288</p>
                <p class="original-price">฿320</p>
              </div>
            </div>
            <div class="item-pic">
              <div
                class="img-box"
                style="background-image: url(assets/images/menu-1.png)"
              ></div>
            </div>
          </div>
        </div>

        <div class="item-div" matRipple>
          <div class="item-box">
            <div class="item-info">
              <p class="item-name">PAD THAI</p>
              <span class="line"></span>
              <div class="tag-row">
                <div class="tag-box" style="background-color: #a69f8e">
                  Contains seafood
                </div>
                <div class="tag-box" style="background-color: #46a482">
                  Healthy
                </div>
              </div>
              <p class="item-desc">
                Marinated Norway salmon served with horseradish and salmon roe,
                microgreens and dills
              </p>
              <div class="price-row">
                <span>Member Price</span>
                <p class="discounted-price">฿441</p>
                <p class="original-price">฿490</p>
              </div>
            </div>
            <div class="item-pic">
              <div
                class="img-box"
                style="background-image: url(assets/images/menu-2.png)"
              ></div>
            </div>
          </div>
        </div>

        <div class="item-div" matRipple>
          <div class="item-box">
            <div class="item-info">
              <p class="item-name">Satay</p>
              <span class="line"></span>
              <div class="tag-row">
                <div class="tag-box" style="background-color: #a69f8e">
                  Contain Nuts
                </div>
                <div class="tag-box" style="background-color: #46a482">
                  Healthy
                </div>
              </div>
              <p class="item-desc">
                Grilled chicken breast, asparagus, baby carrot, potato, snap pea
                and truffle sauce
              </p>
              <div class="price-row">
                <span>Member Price</span>
                <p class="discounted-price">฿315</p>
                <p class="original-price">฿350</p>
              </div>
            </div>
            <div class="item-pic">
              <div
                class="img-box"
                style="background-image: url(assets/images/menu-3.png)"
              ></div>
            </div>
          </div>
        </div>

        <div class="item-div" matRipple>
          <div class="item-box">
            <div class="item-info">
              <p class="item-name">Black Label Burger SO Signature</p>
              <span class="line"></span>
              <!-- <div class="tag-row">
                <div class="tag-box" style="background-color: #a69f8e">
                  Gluten Free
                </div>
                <div class="tag-box" style="background-color: #46a482">
                  Vegetarian certified
                </div>
              </div> -->
              <p class="item-desc">
                Marinated Norway salmon served with horseradish and salmon roe,
                microgreens and dills
              </p>
              <div class="price-row">
                <span>Member Price</span>
                <p class="discounted-price">฿495</p>
                <p class="original-price">฿550</p>
              </div>
            </div>
            <div class="item-pic">
              <div
                class="img-box"
                style="background-image: url(assets/images/menu-4.png)"
              ></div>
            </div>
          </div>
        </div>

        <div class="item-div" matRipple>
          <div class="item-box">
            <div class="item-info">
              <p class="item-name">Parma</p>
              <span class="line"></span>
              <div class="tag-row">
                <div class="tag-box" style="background-color: #a69f8e">
                  Gluten Free
                </div>
                <div class="tag-box" style="background-color: #46a482">
                  Vegetarian certified
                </div>
              </div>
              <p class="item-desc">
                Tomatos, basils, balsamico reduction, olive oil
              </p>
              <div class="price-row">
                <span>Member Price</span>
                <p class="discounted-price">฿423</p>
                <p class="original-price">฿470</p>
              </div>
            </div>
            <div class="item-pic">
              <div
                class="img-box"
                style="background-image: url(assets/images/menu-5.png)"
              ></div>
            </div>
          </div>
        </div>

        <div class="item-div" matRipple>
          <div class="item-box">
            <div class="item-info">
              <p class="item-name">Carbonara</p>
              <span class="line"></span>
              <div class="tag-row">
                <div class="tag-box" style="background-color: #a69f8e">
                  Contains seafood
                </div>
                <div class="tag-box" style="background-color: #46a482">
                  Healthy
                </div>
              </div>
              <p class="item-desc">
                Marinated Norway salmon served with horseradish and salmon roe,
                microgreens and dills
              </p>
              <div class="price-row">
                <span>Member Price</span>
                <p class="discounted-price">฿315</p>
                <p class="original-price">฿350</p>
              </div>
            </div>
            <div class="item-pic">
              <div
                class="img-box"
                style="background-image: url(assets/images/menu-6.png)"
              ></div>
            </div>
          </div>
        </div>

        <div class="item-div" matRipple>
          <div class="item-box">
            <div class="item-info">
              <p class="item-name">Salmon</p>
              <span class="line"></span>
              <div class="tag-row">
                <div class="tag-box" style="background-color: #a69f8e">
                  Gluten Free
                </div>
                <div class="tag-box" style="background-color: #46a482">
                  Healthy
                </div>
              </div>
              <p class="item-desc">
                Grilled chicken breast, asparagus, baby carrot, potato, snap pea
                and truffle sauce
              </p>
              <div class="price-row">
                <span>Member Price</span>
                <p class="discounted-price">฿801</p>
                <p class="original-price">฿890</p>
              </div>
            </div>
            <div class="item-pic">
              <div
                class="img-box"
                style="background-image: url(assets/images/menu-7.png)"
              ></div>
            </div>
          </div>
        </div>

        <div class="item-div" matRipple>
          <div class="item-box">
            <div class="item-info">
              <p class="item-name">Stir Fried Seasonal Vegetables</p>
              <span class="line"></span>
              <div class="tag-row">
                <div class="tag-box" style="background-color: #a69f8e">
                  Gluten Free
                </div>
                <div class="tag-box" style="background-color: #46a482">
                  Vegetarian certified
                </div>
              </div>
              <p class="item-desc">
                Marinated Norway salmon served with horseradish and salmon roe,
                microgreens and dills
              </p>
              <div class="price-row">
                <span>Member Price</span>
                <p class="discounted-price">฿198</p>
                <p class="original-price">฿220</p>
              </div>
            </div>
            <div class="item-pic">
              <div
                class="img-box"
                style="background-image: url(assets/images/menu-8.png)"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--google map-->
    <div class="store-container">
      <div class="store-map">
        <div class="list-heading">
          <p>BRowse by location</p>
          <span>Choose from your favourite location</span>
        </div>
        <div class="map-wrapper">
          <div class="map-panel">
            <div class="map-panel-item">
              <span>Bangkok</span>
              <span class="material-icons md-18">arrow_drop_down</span>
            </div>
            <div class="map-panel-item">
              <span>SO/ Bangkok</span>
              <span class="material-icons md-18">arrow_drop_down</span>
            </div>
            <div class="map-panel-item">
              <span>Red Oven</span>
              <span class="material-icons md-18">arrow_drop_down</span>
            </div>
          </div>
          <div class="google-map">
            <img src="assets/images/test_map.jpg" />
          </div>
        </div>
      </div>
    </div>
    <!--/google-map-->
  </div>
</div>
