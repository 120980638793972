<div class="content">
  <div class="sticky-bar">
    <div class="promo-bar">
      <span class="material-icons">redeem</span>
      <p>Marriot Bonvoy members receive an instant 10% discount at checkout</p>
      <span class="material-icons">redeem</span>
    </div>
  </div>

  <div class="header-banner">
    <div class="banner-container">
      <div class="logo-box">
        <div class="logo-img">
          <img src="assets/images/red_oven_logo.png" />
        </div>
      </div>
      <div class="banner-box" style="background-image: url(assets/images/store-banner.png)"></div>
    </div>
  </div>

  <div class="info-container">
    <div class="info-box">
      <div class="address-box">
        <p>Red Oven - SO/ Bangkok</p>
        <div class="location">
          <span>Location</span>
          <span class="line"></span>
          <p>2 N Sathon Rd, Khwaeng Silom, Bang Rak, Bangkok 10500,
            Thailand</p>
        </div>
        <div class="phone">
          <span>Tel</span>
          <span class="line"></span>
          <p>02 309 3939</p>
        </div>
      </div>
      <div class="opening-hrs">
        <span>Operation hours</span>
        <span class="line"></span>
        <div class="time-table">
          <p>Everyday</p>
          <p>07:00 - 10:30 hrs. (last order 10:00 hrs.)</p>
          <p>12:00 - 15:00 hrs. (last order 14:30 hrs.)</p>
        </div>
      </div>
    </div>
  </div>

  <mat-tab-group mat-align-tabs="center" class="page-select">
    <mat-tab label="Menu Highlights">
      <ng-template matTabContent>
        <app-menu></app-menu>
      </ng-template>
    </mat-tab>
    <!-- <mat-tab label="Reviews">
      <ng-template matTabContent>
        <app-reviews></app-reviews>
      </ng-template>
    </mat-tab>
    <mat-tab label="Book a table">
      <ng-template matTabContent>
        <app-booking></app-booking>
      </ng-template>
    </mat-tab> -->
    <mat-tab label="Info">
      <ng-template matTabContent>
        <app-info></app-info>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

</div>

<!-- <div class="btn-alarm" matRipple>
  <span class="material-icons">notifications_active</span>
</div>


<div class="btn-messager" matRipple>
  <span class="material-icons">chat</span>
</div> -->
